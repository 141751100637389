import React, { useEffect, useState } from "react";
import endPoints from "../../../services/api/root/endPoints";
import { loginCheckService } from "../../../services/checkLogin";

import EnhancedComponents from "../../../components/enhancedComponents/index";
import BasicComponents from "../../../components/basicComponents/index";
import { Link } from "react-router-dom";
import moment from "moment";
import { globalLocalStorage } from "../../../services/storage/localStorage";
import Modal from "react-modal";
import Select from "react-select";
import Moment from "react-moment";
import ReactMde, { Suggestion, SaveImageHandler } from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import userIcon from "../../../assets/images/userIcon.png";
const Loader = require("react-loader");

const customStylesOne = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    minWidth: "50vw",
    maxWidth: "80vw",
    marginRight: "-50%",
    maxHeight: '90vh',
    transform: "translate(-50%, -50%)",
  },
};

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

const Knockknock = () => {
  const [users, setUsers] = useState<any>([]);
  const [messages, setMessages] = useState([]);
  const [selectedUsers, setSelectedUsersState] = useState<any>([]);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [modalOne, setModalOne] = useState(false);
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [body, setBody] = useState("");
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  const [loaded, setLoaded] = useState<any>(true);

  function handleTitle(e: any) {
    setTitle(e.target.value);
  }

  function handleDesc(e: any) {
    setDesc(e.target.value);
  }

  const openModal = () => {
    setModalOne(true);
  };

  function closeModalOne() {
    setModalOne(false);
  }

  function setSelectedUsers(val: any) {
    setSelectedOptions(val);
    const ids: any = [];

    for (let i = 0; i < val.length; i++) {
      ids.push(val[i].value);
    }
    setSelectedUsersState(ids);
  }

  const createKnockKnock = async () => {
    setLoaded(false);
    let user: any = globalLocalStorage.getItem("user");
    user = user ? JSON.parse(user) : user;
    let fields: any = {
      title: title,
      introduction: desc,
      conversation: body,
      everyone: selectedUsers.length ? false : true,
      users: selectedUsers,
      owner: user.id,
      posted_at: new Date(),
    };

    endPoints.createKnockKnock(fields).then((res: any) => {
      setLoaded(true);
      if (res.success) {
        setTitle("");
        setDesc("");
        setBody("");
        setModalOne(false);
        toast.success("Knock-Knock created successfully", {
          position: "top-center",
          autoClose: 6000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        setLoaded(false);
        endPoints.getKnockKnocks().then((res: any) => {
          setLoaded(true);
          if (res.success) {
            setMessages(res.message.reverse());
          }
        });
      } else {
        toast.error("Unable to create Knock-Knock", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
    });
  };

  let sortFunction = (a: any, b: any) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    loginCheckService.check();

    let user: any = globalLocalStorage.getItem("user");
    if (!user) window.location.href = "/home";
    else {
      endPoints.getUsers().then((res: any) => {
        if (res.success) {
          // let filtered: any = res.message.filter((user:any) => {
          // return ((parseInt(moment().format('YYYY')) - parseInt(moment(user.advocateDateOfAdmission).format('YYYY'))) >= 5);
          // })
          let filtered = res.message.filter((user: any) => {
            if (user.payfastpayments.length < 1 && (!user.influencer && !user.guest)) {
              return false;
            }
            return user.profileImage != null;
          });
          filtered = filtered.sort(sortFunction);
          let options: any = [];
          for (let i = 0; i < filtered.length; i++) {
            let tmp = {
              value: filtered[i].id,
              label: `${filtered[i].name} ${filtered[i].surname}`,
            };
            options.push(tmp);
          }
          setUsers([
            {
              label: "Select All",
              value: "all",
            },
            ...options,
          ]);
        }
      });

      endPoints.getKnockKnocks().then((res: any) => {
        if (res.success) {
          setMessages(res.message.reverse());
        }
      });
    }
  }, []);

  return (
    <div className="p-50-20-10 membersList">
      <Loader loaded={loaded}>
        <div className="top holder">
          <div className="one">
            <div className="flexRow">
              <h1 className="primary line">Knock-Knock</h1>
            </div>
            <div className="flexRow history-header">
              <h3 className="primary no-pad">Conversation History</h3>
              <div className="header-btn">
                <BasicComponents.DefaultBtn
                  onClick={openModal}
                  label="New Knock-Knock"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flexRow knock-container">
          {messages.map((knock: any, index: number) => {
            return (
              <div className="knock-card">
                <div className="start-sections">
                  <div className="profile ">
                    <img
                      src={`${
                        knock.owner.profileImage
                          ? `${process.env.REACT_APP_API_URL}${knock.owner.profileImage.url}`
                          : userIcon
                      }`}
                    />
                  </div>
                  <div className="title-section">
                    <div className="name semi-bold primary name-size">
                      {knock.title}
                    </div>
                    <div className="owner">
                      {knock.owner.name} {knock.owner.surname}
                    </div>
                    <div className="blogDate m-top">
                      {knock.knock_messages.length} Replies {knock.views} views
                    </div>
                  </div>
                </div>

                <div className="body-section">{knock.introduction}</div>

                <div className="reply-btn">
                  <Link to={`/knock/${knock.id}`}>
                    <div className="replyBtnContainer">
                      <button className="white replyBtn">VIEW</button>
                    </div>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>

        <Modal
          ariaHideApp={false}
          style={customStylesOne}
          isOpen={modalOne}
          onRequestClose={closeModalOne}
          contentLabel="Example Modal"
        >
          <div className="meetingRequestContainer">
            <h2 className="primary bold text-center">New Knock-Knock</h2>

            <input
              value={title}
              placeholder="Enter Title"
              onChange={handleTitle}
              className="defaultInput"
            />
            <textarea
              name="conversation"
              placeholder="Enter Introduction"
              onChange={handleDesc}
              className="defaultTextarea"
            />

            {/*<textarea
                                name="conversation"
                                onChange={handleBody}
                              className = 'defaultTextarea' placeholder = 'Enter Conversation'/>*/}

            <ReactMde
              value={body}
              onChange={setBody}
              selectedTab={selectedTab}
              onTabChange={setSelectedTab}
              generateMarkdownPreview={(markdown) =>
                Promise.resolve(converter.makeHtml(markdown))
              }
              childProps={{
                writeButton: {
                  tabIndex: -1,
                },
              }}
            />

            <h4 className="primary light">Send Invitation To</h4>

            <Select
              isMulti={true}
              multi={true}
              value={selectedOptions.length ? selectedOptions : null}
              onChange={(selected) => {
                if (selected.find((option) => option.value === "all")) {
                  let tmp = users.slice(1);
                  setSelectedUsers(tmp);
                } else setSelectedUsers(selected);
              }}
              options={users}
            />

            {title.length > 1 && desc.length > 1 && body.length > 1 && (
              <BasicComponents.SecondaryButton
                label="Start Knock-Knock"
                type=""
                onClick={createKnockKnock}
              />
            )}
          </div>
        </Modal>
      </Loader>
    </div>
  );
};

export default Knockknock;
