import React, { useEffect, useState, useLayoutEffect } from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import Modal from "react-modal";

import EnhancedComponents from "../../../components/enhancedComponents/index";
import BasicComponents from "../../../components/basicComponents/index";
import endPoints from "../../../services/api/root/endPoints";
import Parser from "rss-parser";
import { Link } from "react-router-dom";
import moment from "moment";
import { useLocation, useHistory } from "react-router-dom";

import { useAuthenticationProvider } from "../../../context/authenticationProvider";
import { useModalProvider } from "../../../context/modalProvider";
import { ToastContainer, toast } from "react-toastify";

const customStylesOne = {
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export const Homepage = () => {
  const { auth } = useAuthenticationProvider();
  let meetingParticipantsInterval: any = null;
  let parser: any = new Parser();
  const history = useHistory();
  const [rooms, setRooms] = useState<any>(0);
  const [roomsParticipants, setRoomsParticipants] = useState(0);
  const [currentWeekLectures, setCurrentWeekLectures] = useState(0);
  const [modalMessage, setModalMessage] = useState("");
  const [modalHeading, setModalHeading] = useState("");
  const location = useLocation();
  const { toggleModal, active } = useModalProvider();
  const [modalOne, setModalOne] = useState(false);
  const [lectureTitle, setLectureTitle] = useState("");
  const [lectureDesc, setLectureDesc] = useState("");
  const [success, setSuccess] = useState(false);
  const [leftFeed, setLeftFeed] = useState<any>([]);
  const [rightFeed, setRightFeed] = useState<any>([]);
  const [int, setInt] = useState(false);
  const [leftArticleIndex, setLeftArticleIndex] = useState(0); 
  const [rightArticleIndex, setRightArticleIndex] = useState(0); 

  const openModal = () => {
    setModalOne(true);
  };

  function closeModalOne() {
    setModalOne(false);
    setSuccess(false);
  }

  function handleChange(e: any) {
    setLectureTitle(e.target.value);
  }

  function handleDescription(e: any) {
    setLectureDesc(e.target.value);
  }

  const createLectureRequest = async () => {
    if (lectureTitle.length < 3 || lectureDesc.length < 3) {
      toast.error("Please provide title and description", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });

      return;
    }

    endPoints
      .lecturerequests({
        name: lectureTitle,
        description: lectureDesc,
      })
      .then((res: any) => {
        if (res.success) {
          setSuccess(true);
        } else {
          toast.error("Failed to create lecture request", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
        }
      });
  };

  const customModalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "45%",
      minWidth: 200,
      minHeight: 140,
      padding: "30px",
      height: "20vh",
    },
  };

  const getMeetingParticipantsTotal = async () => {
    await endPoints.getMeetingParticipants("all").then((resp: any) => {
      if (resp.message) {
        console.log(resp);
        setInt(true);
        setRoomsParticipants(resp.message.count);
      }
    });
  };

  useEffect(() => {
    if (!int && rooms) {
      getMeetingParticipantsTotal();
      if (!meetingParticipantsInterval) {
        meetingParticipantsInterval = setInterval(
          getMeetingParticipantsTotal,
          10000
        );
      }
    }
  }, [rooms]);

  // useLayoutEffect(() => {
  //   return () => {
  //     if (int) {
  //       clearInterval(meetingParticipantsInterval);
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (location.pathname === "/payment-successful") {
      endPoints.getPayfastInfo().then((res: any) => {
        if (true) {
          setModalHeading("Thank You");
          setModalMessage(`Your debit order setup has been successfully set up.
          
          You now have access to all of the Virtual Chambers features.`);
          toggleModal(true);
          history.replace("/");
        }
      });
    }
  });

  if (location.pathname === "/payment-cancelled") {
    setModalHeading("Error");
    setModalMessage("Your payment was unsuccessful");
    toggleModal(true);
  }

  const getRooms = async () => {
    await endPoints.getRooms().then((roomsRes: any) => {
      if (roomsRes.success) {
        setRooms(roomsRes.message.length);
      } else {
        setRooms(-1);
      }
    });
  };

  useEffect(() => {
    if (!rooms) {
      getRooms();
    }
  }, [rooms]);

  const getCurrentWeekLectures = async () => {
    endPoints.getLectures().then((lecturesRes: any) => {
      if (lecturesRes.success) {
        let data = lecturesRes.message;
        setCurrentWeekLectures(
          data.filter((item: any) => moment(item.date).isAfter(moment())).length
        );
      }
    });
  };

  useEffect(() => {
    if (!currentWeekLectures) {
      getCurrentWeekLectures();
    }
  }, [currentWeekLectures]);

  const getRightFeed = async () => {
    endPoints.getRss().then((rss: any) => {
      if (rss.success) {
        let data = rss.message;

        if (data.data) {
          let feed = data.data;
          if (feed.items) {
            setRightFeed(feed.items.splice(0, 5));
          }
        }
      } else {
        setRightFeed(true);
      }
    });
  };

  useEffect(() => {
    if (!rightFeed.length) {
      getRightFeed();
    }
  }, [rightFeed]);

  const getLeftFeed = async () => {
    await endPoints.getRssSecondary().then((rss: any) => {
      if (rss.success) {
        let data = rss.message;

        if (data.data) {
          let feed = data.data;
          if (feed.items) {
            setLeftFeed(feed.items.splice(0, 5));
          }
        }
      } else {
        setLeftFeed(true);
      }
    });
  };

  useEffect(() => {
    if (!leftFeed.length) {
      getLeftFeed();
    }
  }, [leftFeed]);

  const leftArticleDec = () => { setLeftArticleIndex(leftArticleIndex - 1); }
  const leftArticleInc = () => { setLeftArticleIndex(leftArticleIndex + 1); }

  const rightArticleDec = () => { setRightArticleIndex(rightArticleIndex - 1); }
  const rightArticleInc = () => { setRightArticleIndex(rightArticleIndex + 1); }

  return (
    <div>
      <Modal
        ariaHideApp={false}
        isOpen={active}
        style={customModalStyle}
        contentLabel="Example Modal"
      >
        <EnhancedComponents.CustomModal
          heading={modalHeading}
          content={modalMessage}
        />
      </Modal>
      <div className="heroImage">
        <div className="holder">
          <div className="introGrid">
            <div className="top center tcenter">
              <img
                className="whiteLogo"
                src={require("../../../assets/images/logoWhite.png")}
              />
              <h2 className="white">
                JOIN THE CHAMBERS OF FORWARD-THINKING ADVOCATES
              </h2>
            </div>
            <div className="four">
              <div className="introCard right">
                <img
                  className="introIcon"
                  src={require("../../../assets/images/edit.png")}
                />
                <h3 className="white introHeading">Fully LPC compliant</h3>
                <p className="white introText">
                  A group of fully LPC compliant Advocates
                </p>
              </div>
              <div className="introCard right">
                <img
                  className="introIcon"
                  src={require("../../../assets/images/noCoins.png")}
                />
                <h3 className="white introHeading">No shared costs</h3>
                <p className="white introText">
                  Only pay your monthly Virtual Chambers membership
                </p>
              </div>
              <div className="introCard right">
                <img
                  className="introIcon"
                  src={require("../../../assets/images/globe.png")}
                />
                <h3 className="white introHeading">Your chambers</h3>
                <p className="white introText">
                  Geographically unlimited, choose where you practise from,
                  irrespective of the Division
                </p>
              </div>
              <div className="introCard">
                <img
                  className="introIcon"
                  src={require("../../../assets/images/headset.png")}
                />
                <h3 className="white introHeading">Members forum</h3>
                <p className="white introText">
                  Virtual common rooms, excess work referrals, in-house Q&A
                  platforms and lectures
                </p>
              </div>
            </div>
            {/* {!auth && <BasicComponents.DefaultBtn onClick={null} label="join virtual chambers" />} */}
          </div>
        </div>
      </div>
      <div className="commonRoomSection">
        <div className="holder">
          <div className="one">
            <div className="top">
              <h1 className="white">Join The Common Rooms</h1>
              <h3 className="white">Currently...</h3>
            </div>
            <div className="four center">
              <div className="commonRoomTile center right">
                <div className="flexRow">
                  <h1 className="contentUnit white">
                    {rooms !== -1 ? rooms : 0}
                  </h1>
                  <img
                    className="commonRoomIcon"
                    src={require("../../../assets/images/messageIcon.png")}
                  />
                </div>
                <p className="white contentText">Common rooms are in use.</p>
              </div>
              <div className="commonRoomTile center right">
                <div className="flexRow">
                  <h1 className="contentUnit white">
                    {roomsParticipants !== -1 && roomsParticipants ? roomsParticipants : 0}
                  </h1>
                  <img
                    className="commonRoomIcon"
                    src={require("../../../assets/images/userGroup.png")}
                  />
                </div>
                <p className="white contentText">Advocates are in discussion</p>
              </div>
              <div className="commonRoomTile center">
                <div className="flexRow">
                  <h1 className="contentUnit white">{currentWeekLectures}</h1>
                  <img
                    className="commonRoomIcon"
                    src={require("../../../assets/images/scaleIcon.png")}
                  />
                </div>
                <p className="white contentText">Upcoming lectures</p>
              </div>
              <div className="commonRoomTile center">
                <h3 className="white contentText">Daily Common Rooms</h3>
                <p className="white contentText">
                  Morning Coffee |{" "}
                  <span className="contentDetail">8:30 am</span>
                </p>
                <p className="white contentText">
                  Lunch Chat | <span className="contentDetail">12:30 pm</span>
                </p>
                <p className="white contentText">
                  Afternoon Tea |{" "}
                  <span className="contentDetail">16:00 am</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lectures">
        <div className="backgroundImage">
          <div className="holder">
            <div className="lecturesTable one center">
              <h1 className="primary tcenter">About Our Lectures</h1>
              <p className="primary tcenter lectureContent">
                Get access to lectures and chat groups by Judges, senior
                colleagues, published authors and field specialists.
              </p>
              <h1 className="primary">Upcoming Topics</h1>
              <div className="homepageTableHolder">
                <EnhancedComponents.LecturesTable />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="blogs">
        <div className="holder">
          <div
            className={"jc-sb"}
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <h1 className="secondary mv-10-0">Industry News</h1>
            <div className="defaultBtnContainer mv-0-10">
              <a
                style={{ textDecoration: "none" }}
                target="_blank"
                href="https://www.dailymaverick.co.za"
              >
                <button type="button" className="defaultBtn white">
                  Visit The Daily Maverick
                </button>
              </a>
            </div>
          </div>

          <h3 className="secondary underline">Stay Informed</h3>
          {leftFeed.length && rightFeed.length ? (
            <div className="feeds-mobile resp-hide-reverse">
              <div className="feeds-mobile-section">
                <img
                  className="lpcLogoSml"
                  src='https://lpc.org.za/wp-content/uploads/2020/12/LPC-WEB-LOGO.png"'
                />
                <div className="bottomRight" key={leftFeed[leftArticleIndex].title}>
                  <h4 className="bottomBlogHeading uppercase secondary">
                    {" "}
                    {leftFeed[leftArticleIndex].title}
                  </h4>
                  <p className="secondary blogContentMobile paragraph">
                    {leftFeed[leftArticleIndex].contentSnippet.slice(0, 90)}...
                  </p>
                  <a
                    className="secondary blogContent"
                    style={{
                      textTransform: "uppercase",
                      textDecoration: "none",
                      color: "#1F224A",
                      cursor: "pointer",
                    }}
                    href={leftFeed[leftArticleIndex].link}
                    rel="noreferrer"
                    target="_blank"
                  >
                    &nbsp;Read More
                  </a>
                </div>
              </div>
              <div className={'feed-mobile-btns'}>
                  <button {...(leftArticleIndex <= 0 ? {disabled: true} : {disabled: false})} onClick={leftArticleDec}></button>
                  <button {...(leftArticleIndex >= leftFeed.length - 1 ? {disabled: true} : {disabled: false})} onClick={leftArticleInc}></button>
                </div>
              <div style={{width: '100%', height: 1, backgroundColor: 'grey', marginTop: 25, marginBottom: 20}}></div>
              <div className="feeds-mobile-section">
                <div className="bottomRight" key={rightFeed[rightArticleIndex].title}>
                  <h4 className="bottomBlogHeading uppercase secondary">
                    {" "}
                    {rightFeed[rightArticleIndex].title}
                  </h4>
                  <p className="secondary blogContentMobile paragraph">
                    {rightFeed[rightArticleIndex].contentSnippet.slice(0, 90)}...
                  </p>
                  <a
                    className="secondary blogContent"
                    style={{
                      textTransform: "uppercase",
                      textDecoration: "none",
                      color: "#1F224A",
                      cursor: "pointer",
                    }}
                    href={rightFeed[rightArticleIndex].link}
                    rel="noreferrer"
                    target="_blank"
                  >
                    &nbsp;Read More
                  </a>
                </div>
              </div>
              <div className={'feed-mobile-btns'}>
                  <button {...(rightArticleIndex <= 0 ? {disabled: true} : {disabled: false})} onClick={rightArticleDec}></button>
                  <button {...(rightArticleIndex >= rightFeed.length - 1 ? {disabled: true} : {disabled: false})} onClick={rightArticleInc}></button>
                </div>
            </div>
          ) : null}
          <div className="two resp-hide-1024" style={{ flexDirection: "row" }}>
            <div className="blogLeft">
              <div className="blogBottom two">
                <img
                  className="lpcLogo"
                  src='https://lpc.org.za/wp-content/uploads/2020/12/LPC-WEB-LOGO.png"'
                />
                {leftFeed.map((item: any) => {
                  return (
                    <div className="bottomRight" key={item.title}>
                      <h4 className="bottomBlogHeading uppercase secondary">
                        {" "}
                        {item.title}
                      </h4>
                      <p className="secondary blogContent paragraph">
                        {item.contentSnippet}
                      </p>
                      <a
                        className="secondary blogContent"
                        style={{
                          textTransform: "uppercase",
                          textDecoration: "none",
                          color: "#1F224A",
                          cursor: "pointer",
                        }}
                        href={item.link}
                        rel="noreferrer"
                        target="_blank"
                      >
                        &nbsp;Read More
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="blogRight">
              <div className="blogBottom two">
                {rightFeed.map((item: any) => {
                  return (
                    <div className="bottomRight" key={item.title}>
                      <h4 className="bottomBlogHeading uppercase secondary">
                        {" "}
                        {item.title}
                      </h4>
                      <p className="secondary blogContent paragraph">
                        {item.contentSnippet}
                      </p>
                      <a
                        className="secondary blogContent"
                        style={{
                          textTransform: "uppercase",
                          textDecoration: "none",
                          color: "#1F224A",
                          cursor: "pointer",
                        }}
                        href={item.link}
                        rel="noreferrer"
                        target="_blank"
                      >
                        &nbsp;Read More
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        ariaHideApp={false}
        style={customStylesOne}
        isOpen={modalOne}
        onRequestClose={closeModalOne}
        contentLabel="Example Modal"
      >
        {!success ? (
          <div className="meetingRequestContainer">
            <h2 className="primary bold noMarginBottom">Request a Topic</h2>

            <h4 className="primary light">Topic Title</h4>
            <input
              value={lectureTitle}
              onChange={handleChange}
              className="defaultInput"
            />
            <h4 className="primary light">More Information</h4>

            <textarea
              onChange={handleDescription}
              className="defaultInput"
              rows={4}
              cols={50}
            ></textarea>

            <BasicComponents.SecondaryButton
              label="Create"
              type=""
              onClick={createLectureRequest}
            />
          </div>
        ) : (
          <div className="meetingRequestContainerSuccess">
            <h2 className="primary bold noMarginBottom">Request a Topic</h2>
            <p>Your topic request has been sent</p>
            <BasicComponents.SecondaryButton
              label="Finish"
              type=""
              onClick={closeModalOne}
            />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Homepage;
