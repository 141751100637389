import React from 'react';

import EnhancedComponents from '../../../components/enhancedComponents/index';
import BasicComponents from '../../../components/basicComponents/index';

const Tos = () =>{
  return (
    <div className='top holder primary'>
      <div className = ' tcenter'>
          <h1 className = 'line primary'>Terms and Conditions</h1>
      </div>
      <h2>Introduction</h2>
      <p>
      Virtual Chambers (the Portal) is a platform that is used by a group of advocates (the Group) and which is owned and managed wholly by Virtual Chambers Propriety Limited (Registration Number: 2020/490972/07) (the Company).
      </p>
      <h2>Application of These Terms and Conditions</h2>
      <p>
        These terms and conditions (Terms) apply to the Portal that is owned by, provided by and managed by the Company that may be accessed at the internet website with the address http://www.virtualchambers.co.za/ and includes any content or services which are included in or accessible from the Portal. 
      </p>
      <p>
        These Terms are binding on everyone who becomes a member of the Group and who is subsequently granted access to and/or use of the Portal. By using this Portal, the person accessing the Portal and/or using the Portal (the User) agrees to be bound by and shall be regarded to have accepted to abide by these Terms, and acknowledges to have read and understood them. If the User does not agree to any of the Terms, the User must not become a member of the Group. The Group is a voluntary association of independent advocates comprised of all of the members of the Group. Every User hereby agrees to practice under the name and style of Virtual Chambers, without limiting any members independent association to any other group or affiliations to any association.
      </p>
      <p>
        The Portal is a platform which provides Users with access to other members of the Group, the professional image, structure and support of being part of the Group, and access to the features of the portal to facilitate members in providing high quality legal advocacy services. 
      </p>
      <p>
        The Company reserves the right to and may occasionally make any changes to these Terms by uploading the amended Terms on the Portal. If the User continues to use this Portal, after the changes have been uploaded, this action shall represent the User’s agreement to the updated Terms.
      </p>
      <h2>Membership Fees </h2>
      <p>
        When a person accepts these Terms and becomes a member of the Group, he or she must complete the payment section to authorize the activation of a monthly debit order, and herewith gives consent for the monthly membership fee to be deducted therefrom on the first day of every calendar month. 
      </p>
      <p>
        In the circumstances where payment for membership fees is not made on time, the Company has the sole discretion to terminate membership and deny access into the Portal immediately without prior notice to the relevant member. A grace period and notice to pay may be given at the sole discretion of the company.
      </p>
      <p>
        A User must pay monthly membership fees which, during the 2021 calendar year, will be an amount of R3 500 (three thousand five hundred Rand) excluding VAT.
      </p>
      <p>
        The membership fees will increase annually which increase will be directly related, but not limited to, the following factors:
      </p>
      <ul>
        <li>The rate of the consumer price index.</li>
        <li>Costs and maintenance of the Portal.</li>
        <li>Management costs of the Portal by the Company.</li>
        <li>Costs of added facilities should 60% (sixty percent) of the Group members require and agree to supplementing the available facilities.</li>
      </ul>
      
      <h2>Costs and Expenses</h2>

      <p>
        The User hereby acknowledges and agrees that the Company shall only be responsible for the management of the Portal and for providing its members with access to or use of the Portal, together with the costs and expenses associated therewith. 
      </p>
      <p>
        The User shall be solely responsible for any and all costs and expenses, whether directly or indirectly, related to their rendering their services as an advocate. Neither the Company nor the other Members will acquire any responsibility, financial or otherwise, for the costs or expenses incurred by another member.
      </p>
      <p>
       All costs incurred by a User for its use of and access to the Portal and any and all information, content, materials and services included or otherwise made available to the User therein shall be for the User’s own account.
      </p>
      <p>
       The Company shall under no circumstances be liable for any damages, injury, loss, costs or expenses suffered or incurred by a User attributable to the services provided by a User whether or not such services are provided pursuant to a User using the Portal. 
      </p>

      <h2>Grant of Rights and Use of Portal</h2>
      <p>The Company in its sole discretion grants to the User the right to access and use the Portal upon acceptance of these Terms. </p>
      <p>Advocates practising or intending to practice as a legal practitioner conducting a trust account practice may not become members of the Group and shall not be granted access to or use of the Portal. Only advocates performing services on an attorney referral basis may become a member of the Group and be entitled to use and access the Portal.</p>
      <p>The rights granted to the User by way of these Terms are royalty free, personal, revocable, non-transferable, limited and non-exclusive. </p>
      <p>The User shall not be entitled to use the Portal for any other purpose than that mentioned in these Terms and usually associated with the practice of an advocate.</p>
      <p>The User may not use, reproduce, adapt, distribute, publish or in any other way deal or interfere with the Portal’s contents without the prior written consent of the Company.  </p>
      <p>The Company reserves the right to make any changes to the Portal, its content and/or services offered through the Portal at any time and without prior notice.</p>
      <p>The content published by the User on the Portal reflects the views of the relevant User or author and does not necessarily represent the official opinion of the Group unless otherwise stated.</p> 
      <p>A User shall not post on the Portal any content that is illegal, unlawful, obscene, defamatory, discriminatory, disparaging of others, derogatory, inflammatory, harassing, insulting, offensive, political or likely to promote violence or hatred against others or that contains abusive, offensive or profane language. A User shall also not post on the Portal any link to any such content. </p>

      <p>The Company encourages the User to report illegal, defamatory, infringing, incorrect or harmful content from the Portal to the Company and the Company undertakes to correct or remove such content or any part thereof, should it in the sole discretion of the Company be considered to be appropriate to do so.</p>
<p>Although every effort will be made to have the Portal available at all times, the Portal may become unavailable for reasons including (without limitation) maintenance or repairs, loss of connectivity, upgrading, add-in of new features or some other form of interruption. The Company does not warrant against nor will it be held liable for such downtime and the User indemnifies the Company against any loss, damage, claims, costs or penalties incurred as a result of such unavailability. </p>
<p>If the User commits any breach of these Terms or in any other way interacts with or uses the Portal in an unlawful or unauthorised manner, the Company shall be entitled, in its sole and absolute discretion, to terminate the User’s access to the Portal immediately, without prior notice, without any liability on the Company’s part and without prejudice to the Company’s rights in terms of these Terms or at law. </p>
<p>Should a User’s access to the Portal be terminated, the Company may in its absolute and sole discretion, terminate such User’s membership with the Group as well, or impose such other requirements to continue membership with the Group as it deems appropriate in its absolute and sole discretion. </p>

<h2>User Registration and Access</h2>

<p>The User acknowledges that the User shall be required to capture information relating to the User’s identity, contact particulars, area of practice, relevant dates of admission as an advocate and pupilage and relevant qualifications upon registration in order to create a profile and an account with which the User can log on to the Portal. The User will not be required to provide any other form of personal information. The information provided will be used to create a Member’s profile on the portal to which the public and other members will have access.</p>
<p>The information collected from the User is for the sole purpose of creating a User profile which will be available to the public on the Portal. The Company shall not use this information for any other purposes, without the prior written consent of the User. </p> 
<p>The User accepts and acknowledges the he or she must have access to ZOOM in order to utilise the facilities on the portal.</p>
<p>The User accepts full responsibility for all activities that occur under the User’s access details or password and accepts responsibility for sharing his or her User name and password. </p>
<p>If the User chooses or is provided with identification codes, usernames, passwords or any similar form of identification information as part of the Portal’s security systems, the User must keep this information secret and confidential and must not permit anyone else to use it. The User shall be responsible for all access to the Portal with the User’s username and password. When the User’s username and password have been used in order to gain access to the Portal, the Company shall be entitled to assume that such use and all related communications emanate from the User. The Company shall not be liable for any loss or damage arising from unauthorised use of the User’s identification information. </p>
<p>In the event that the User becomes aware of a breach of the confidentiality of the User’s username and password, the User must immediately communicate this to the Company  with subsequent confirmation in writing. The compromised username and password will be deactivated as soon as reasonably possible and a new username and password will be issued to the User. The Company may, in its sole and absolute discretion and for any reason whatsoever, require the User to change his username and password at any time. </p>
<p>Once the User has logged onto the Portal, certain information, functionality and other features of the Portal remain accessible upon subsequent access to the Portal without the User having to re-enter his or her password. If the User prefers to enter his or her password every time the User accesses the Portal, the User can change the setting in the menu option.</p>
<p>	The User shall be allowed to utilise a “Reset password” and “Forgot password” function through either e-mail or SMS. The User acknowledges that the User’s access to the Portal may be refused if information cannot be verified. </p>
<p>	The User agrees that the Company cannot be held responsible for charges incurred by the User in respect of accessing the Portal or for any usage of the Portal, including but not limited to data and text message charges. </p>

<h2>Portal Enhancements and Support</h2>
<p>The User agrees that the Portal may automatically be enhanced or upgraded for the continued functionality of the Portal or for any reasonable business purpose and the User consents to such enhancements and upgrades. </p>
<p>	The Company will be responsible for the general maintenance and support services in respect of the Portal. </p>
<h2>	User Content</h2>
<p>The User acknowledges that the Company does not editorially control, filter or read information submitted by the User on the Portal (User Content) on an ongoing basis, and acknowledges and agrees that the Company shall not be responsible, in any way, for any User Content. Any User that views and relies on User Content shall do so at the User’s own risk. The User hereby waives any claim of any nature whatsoever against the Company suffered by the User in respect of User Content.  </p>
<p>Warrantires and indemnity regarding the services</p>
<p>The Company makes no warranties or representations in respect of the Portal.</p>
<p>	By using the Portal, the User agrees to hold the Company harmless in respect of any liability or damage that might arise out of a User’s utilisation of the Portal. Neither the Company nor its officers, employees, agents and contractors shall be responsible for the conduct, whether online or offline, of any User. </p>
<p>	The Company shall not be liable for any damages, injury, loss, cost or expense suffered or incurred by Users attributable to services provided pursuant to a User using the Portal. </p>
<p>	To the fullest extent permitted by law, the User indemnifies and holds the Company, its officers, employees, agents and contractors harmless against all claims, liability, injury, losses, costs, expenses and penalties arising from or related to the services procured and/or provided by Users to other Users on the Portal. </p>

<p>	The User warrants that it shall for the entire duration of his/her membership with the Group strictly comply with the provisions of the Legal Practice Act 28 of 2014, (the Act), and that it shall ensure that any fees and charges due by it in terms of the Act to the Legal Practice Council shall be paid in full before or on the due date for such payment. </p>
<h2>	Liabilities Regarding Use of the Portal</h2>
<p>	The User joins as a member of the Group and makes use of the Portal at their own risk. </p>
<p>	The Portal and all information, content, materials, including Uniform Resource Locator (“URL”) links to any other web addresses, and any services included or otherwise made available to the User on the Portal are provided on an “as is” and an “as available” basis. The Company makes no warranties or representations of any kind, express or implied, as to the operation of this Portal or the available information, content, materials, including URL links to any other web addresses, or any services included on or otherwise made available to the User. </p>
<p>	Notwithstanding anything to the contrary contained in these Terms, the Company shall have no liability for any loss, damage, cost, claim or penalty of whatsoever nature including, but not limited to, indirect and consequential loss or damage and loss of profits, however arising out of or in connection with these Terms or the Portal, whether caused by latent or patent defects in the Portal, the use of the Portal and/or information contained on the Portal or otherwise.</p>
<p>	The Company will not be held liable for any delay, failure, breach or non-compliance with its obligations under these Terms. </p>
<p>	These Terms do not intend to, nor shall they be interpreted to, limit the liability of the Company in any way which would be illegal for the Company to exclude or attempt to exclude or where such exclusion is prohibited by the Consumer Protection Act, 2008 or the Electronic Communications and Transactions Act, 2002, or any other law in force from time to time in the Republic of South Africa. </p>
<h2>Disclaimer </h2>
<p>Any commentary, advice, information, suggestions, opinions, answers, lectures/training provided or any other information posted on the Portal is not intended to nor shall it be interpreted to amount to advice on which reliance should be placed and are posted merely for guidance and facilitation purposes only. The User makes use of any such information at their own risk and in their own discretion and disclaims and indemnifies the Company from and against any and all liability and responsibility arising from any reliance placed on such information posted on the Portal.</p>
<h2>Intellectual Property</h2>
<p>	The Portal is owned by the Company and the User acknowledges that the Company and its licensors are the proprietors of any and all intellectual property subsisting in, pertaining to or used on the Portal including, without limitation, patents, inventions, copyright, trade marks, goodwill and/or trade secrets (Intellectual Property) associated with, related to or appearing on the Portal.</p>
<p>	The content of the Portal, including but not limited to any pictures, photos, videos, text, presentations, names, titles, brands, drawings, models and associated software are protected by South African law. The owners of such content reserve all such rights therein unless provided otherwise in these Terms. </p>

<p>The User undertakes:</p>
<p>	not to use or register any trade mark(s), trade name(s) or other device(s) which are or incorporate marks which are the same as or confusingly similar to the Company’s trade mark(s) or which marks are likely to be related to any of the Company’s trade mark(s) or where such use would take unfair advantage of or be detrimental to the distinctive character or the reputation of any of the Company’s trade mark(s);</p>
<p>	not at any time to do or cause to be done any act or thing in any way impairing or tending to impair any part of the Company’s rights, title and interest in and to the Intellectual Property; and</p>
<p>	not in any way to make unauthorised use of the Intellectual Property or to represent that he has any rights of any nature in the Intellectual Property or any registrations thereof.</p>
<p>Without limiting the generality of the provisions of clause 12.3, the User undertakes not to make any unauthorised use, reproductions or copies of any work or material displayed or made available on the Portal and agrees to adhere to and comply with all policies, conditions of use and rules that may apply to the use of such work or material.</p>
<p>The Portal’s graphics, logos, page headers, button icons, scripts and service names are the trade marks of the Company. The Company’s trade marks may not be used in connection with any product or service that is not conducted by the Company or in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits the Company. The User may not use any of the Intellectual Property without the Company’s prior written consent. All other trade marks not owned by the Company that appear on the Portal are the property of their respective owners who may or may not be affiliated with, connected to, or sponsored by the Company. The User may not use such trade marks without prior written consent from the relevant owners.</p>
<p>All Members are entitled to use the logo together with the name VIRTUAL CHAMBERS as stationary.</p>
<h2>Malicious Software and Offenses</h2>
<p>The User undertakes that: </p>
<p>the User will not use the Portal in any way that causes, or is likely to cause, the Portal and access to the Portal to be interrupted, damaged or impaired in any manner;</p>
<p>no form of virus, Trojans, worms, logic bombs, or other malicious coding, virus or software will be introduced onto the Portal or into the Company’s system which may cause any form of technological harm or any other form of harm in any manner or respect;</p>
<p>the User will not attempt to gain unauthorised access to the Portal; and</p>
<p>the User will not attempt to gain unauthorised access to the Portal’s server, databases, computers or any other device associated with the Portal and will not attack the Portal through a denial-of-service attack or a distributed denial-of-service attack.</p>

<p>Any breach of these Terms regarding malicious software and offenses may be reported to the relevant law enforcement agencies and the Company will co-operate in all respects with those law enforcement agencies, including by way of disclosure of the identity and identification information of the User. </p>
<p>	Although the Company and its representatives will use reasonable efforts to ensure that no malicious content can be received from the Portal, the Company does not warrant that the Portal is free of malicious content or viruses and the Company will not be held liable for any loss resulting from a distributed denial-of-service attack, or any malicious content as described in clause 13.1.2. which may infect any User’s computer or device, computer equipment, data or any other propriety material where such loss is or may be attributed to the User’s use of the Portal or downloads received from the Portal. </p>
<p>The User warrants that he/she will not use the Portal in any manner that will break any law enforceable in South Africa or cause any annoyance, unnecessary anxiety, nuisance or inconvenience to any person.</p>

<h2>Suspension of Termination</h2>
<p>A User may terminate their membership with the Group for any reason whatsoever on 1 (one) calendar month’s prior written notice. Upon termination of a User’s membership with the Group (Termination Date), the User’s activity on or access to the Portal shall be terminated and any and all amounts which may be owing by the User to the Company, notwithstanding any agreement to the contrary, shall immediately become due and payable. The user shall similarly immediately terminate the use of the Company’s logo and name as stationary.</p>
<p>The Company reserves the right to at any time suspend or terminate the Portal or any activity on or access to the Portal for any reason, including (without limitation) any misconduct or unlawful use of the Portal by any User or for any reasons relating to any law, legislation or regulation.</p>
<p>In the event that the Portal, any activity or event on the Portal or any of the services is suspended or terminated, a User shall have no claim against the Company for whatever reason.</p>
<h2>Addresses</h2>
<p>Each Party chooses the addresses set out opposite its name below as its addresses to which all notices and other communications must be delivered for the purposes of this Agreement and its domicilium citandi et executandi (Domicilium) at which all documents in legal proceedings in connection with this Agreement must be served:</p>
<p>The Company: As per clause 17</p>		
<p>The User: As per the address supplied by the User upon registering with the Portal.</p>
<p>Any notice or communication required or permitted to be given to a Party pursuant to the provisions of this Agreement shall be valid and effective only if in writing and sent to a Party’s chosen email address, provided that documents in legal proceedings in connection with this Agreement may only be served at a Party’s Domicilium.</p>
<p>Any notice by e-mail to a Party at its e-mail address shall be deemed, unless the contrary is proved, to have been received on the first business day after the date of transmission.</p>
<p>Notwithstanding anything to the contrary contained in this clause [15], a written notice or communication actually received by a Party shall be an adequate written notice or communication to it notwithstanding that it was not sent to or delivered at its chosen address in terms of clause 15.1.</p>
<h2>General</h2>
<p>These Terms will be governed by and construed in accordance with the laws of the Republic of South Africa.</p>
<p>If at any time there is a failure by the Company to insist on strict performance of any of the User’s obligations under these Terms and related contracts, this shall not be construed to be a waiver of such rights and shall not relieve the User from compliance with such obligations. A waiver of any one default is not to be interpreted as a condonation of any other or further defaults.</p>
<p>The Company reserves the right to amend or delete any part of the Portal, the related Terms and/or Privacy Policy at any time and without prior notice. The User warrants that they will review the Terms on a continual basis and remain up-to-date in respect of any changes.</p>
<p>Any and all communications between the Parties, whether legal or merely for notification purposes, correspondence or for any other reason will only satisfy any legal requirement if it is reduced to writing whether electronic or otherwise.</p>
<p>The company retains the right to terminate the use of the portal partly or in it’s entirety at it sole discretion but with reasonable notice via email to the email address provided upon registration.</p>

    </div>
  )
}

export default Tos